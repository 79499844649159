import moment from "moment";
import { UPLOAD_URL } from "../config";

export function formatImage(filename = "") {
  if (filename) {
    if (filename.startsWith("http://") || filename.startsWith("https://")) return filename;

    return UPLOAD_URL + "/" + filename;
  }
}

export function formatTextWithLink(description: string = "") {
  return description.replace(
    /(?:https?|http):\/\/[^\s]+/g,
    (url) => `<a href="${url}" target="_blank" class="text-cyan-500" rel="noreferrer" >${url}</a>`
  );
}

export function formatDate(date: string = "", format = "DD MMM, YYYY") {
  return moment(date).format(format);
}
