import axios, { AxiosError } from "axios";

import { API_ENDPOINT } from "../config";

export const axiosMiddleware = () => {
  axios.interceptors.request.use(function (config) {
    config.baseURL = API_ENDPOINT;
    config.timeout = 30000;
    config.withCredentials = true;
    return config;
  });
  axios.interceptors.response.use((response) => response, manageErrorConnection);
};

function manageErrorConnection(err: AxiosError) {
  return Promise.reject(err);
}

export default axios;
